/* You can add global styles to this file, and also import other style files */
@layer app {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    
  }

  body {
    background-color: #fafafa;
  }

  .app {
    display: flex;
    flex-direction: column;
  }

  .app-header {
    display: flex;
    flex-direction: column;
  }

  .app-content {
    display: flex;
    flex-direction: column;
    margin: 30px 50px 20px;
  }

  .component-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .component-title {
    font-family: sans-serif;
    margin-top: 9px;
  }

  .component-body {
    display: flex;
    flex-direction: column;
    max-width: 83vw;
    padding-left: 80px;
    box-sizing: border-box;
    width: 100%;
  }
}